
import { defineComponent } from "vue";

export default defineComponent({
    name: 'MainFooter',
    computed:{
        CurrentYear():number{
            return (new Date()).getFullYear();
        }
    }
});
