import InlineSvg from 'vue-inline-svg';

// bootstrap
//import 'bootstrap'

/* only needed
*/
//import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
//import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
//import 'bootstrap/js/dist/popover';
//import 'bootstrap/js/dist/scrollspy';
//import 'bootstrap/js/dist/tab';
//import 'bootstrap/js/dist/toast';
//import 'bootstrap/js/dist/tooltip';

import '@/scss/main.scss'
// done in main.sccs 
import 'bootstrap-icons/font/bootstrap-icons.scss'

//storage
// check this import for vuex
// import 'es6-promise/auto'

import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import { store, key } from '@/store'

const app = createApp(App);
app.component('inline-svg', InlineSvg);
app.use(store, key)
app.use(router);
app.mount('#app');
