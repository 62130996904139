import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "HomeRoute" */ '@/views/Home.vue')
  },
  {
    path: '/reserva-online',
    name: 'Reserva Online',
    component: () => import(/* webpackChunkName: "ReservaOnlineRoute" */ '@/views/Reserva.vue'),
    props: { tipo: "ONLINE" },
  },
  {
    path: '/reserva-presencial',
    name: 'Reserva Presencial',
    component: () => import(/* webpackChunkName: "ReservaPresencialRoute" */ '@/views/Reserva.vue'),
    props: { tipo: "PRESENCIAL" },
  },
  {
    path: '/examenes',
    name: 'Examenes',
    component: () => import(/* webpackChunkName: "ReservaPresencialRoute" */ '@/views/Examenes.vue'),
  },
  {
    path: '/formulario', 
    name: 'Formulario',
    component: () => import(/* webpackChunkName: "FormularioRoute" */ '@/views/Formulario.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
})

export default router
