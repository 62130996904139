import { InjectionKey } from 'vue'
//import createPersistedState from "vuex-persistedstate";
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { TipoContacto } from '@/data/contacto'
import { base33ID as uuid } from '@/utils/uuid';
import { Reserva } from '@/models/reserva';
import { apiInstance } from '@/axios/axios_reservas';

// define your typings for the store state
export interface State {
  reserva: Reserva
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

const getDefaultState = () =>{
  return {
    reserva: {
      id: uuid(),
      timestamp: Date.now(),
      tipo: "test",
      temaId: 0,
      subTema: "",
      comentario: "",
      nivelId: -1,
      subNivel: "",
      contacto: { tipo: TipoContacto.None, info: "" },
      sent: false,
      sentTimestamp:0
    }
  }
}

export const store = createStore<State>({
  state:getDefaultState(),
  getters: {
    reserva: state => state.reserva,
    timestap: state => state.reserva.timestamp
  },
  mutations: {
    updateReservaTipo(state, payload: string) {
      state.reserva.tipo = payload;
    },
    updateReservaTemaId(state, payload: number) {
      state.reserva.temaId = payload;
    },
    updateReservaSubTema(state, payload: string) {
      state.reserva.subTema = payload;
    },
    updateReservaComentario(state, payload: string) {
      state.reserva.comentario = payload;
    },
    updateReservaNivelId(state, payload: number) {
      state.reserva.nivelId = payload;
    },
    updateReservaSubNivel(state, payload: string) {
      state.reserva.subNivel = payload;
    },
    updateReservaContactoTipo(state, payload: TipoContacto) {
      state.reserva.contacto.tipo = payload;
    },
    updateReservaContactoInfo(state, payload: string) {
      state.reserva.contacto.info = payload;
    },
    updateReservaSent(state, payload: boolean) {
      state.reserva.sent = payload;
    },
    resetState(state){
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    syncWithBackend({commit, state}) {
      if (state.reserva.sent == false) {
        apiInstance.post("api/reservas", state.reserva).then(
          resp => {
            const rOriginal = JSON.stringify(state.reserva)
            const respReserva = JSON.stringify(resp.data)
            if (respReserva == rOriginal) {
              console.log("reserva has been sent to server correctly")
              commit("updateReservaSent", true)
            } else {
              console.log("reserva mismatch ")
              console.log("expected:");
              console.log(rOriginal)
              console.log("got:")
              console.log(respReserva)
            }

          },
          err => {
            console.log("error sending data to backend " + err)
            return Promise.reject(err)
          }
        )
      }else{
        apiInstance.put("api/reservas", this.state.reserva).then(
          resp => {
            if(resp.data == {"message": "updated " + this.state.reserva.id }){
              console.log("update OK")
            }else{
              console.log("error in back end sync message " + resp.data)
            }
          }, 
          err => {
              console.log("error in back end sync " + err)
              console.log("reposne error was " + err.response)
          }
        )
      }
    },
  },
  //plugins: [createPersistedState()],
})

// define your own `useStore` composition function
export function useStore(): Store<State> {
  return baseUseStore(key)
}
