
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    MainHeader,
    MainFooter
  },
});

