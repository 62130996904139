export function base33ID(): string {
	const alphabet = "123456789ABCDEFGHJKLMNPQRSTUVWXYZ" // base58
	const size = 12
	let id = ""
	for (let i = 0; i < size; i++) {
		if (i % 4 == 0 && i != 0) {
			id += '-';
		}
		id += alphabet.charAt(Math.floor(Math.random() *
			alphabet.length));
	}
	return id
}
