import axios from 'axios'

let csrfToken = ""
let waitCsrfToken = false;
const TOKEN_HEADER_NAME = "x-csrf-token"
let cookie: string;

export const apiInstance = axios.create(
    {
        withCredentials: true,
        baseURL: 'https://testp10api.iteasys.com',
        timeout: 1000,
    }
);

// Request interceptor
apiInstance.interceptors.request.use(
    async req => {
        switch (req.method) {
            case "get":
                break;
            default:
                await checkCsrfToken()
                if(req.headers)
                req.headers[TOKEN_HEADER_NAME] = csrfToken;
                
                if (process.env.NODE_ENV == "test" && req.headers)
                    req.headers["Cookie"] = cookie
        }
        return req;
    }, error => {
        return Promise.reject(error)
    }
)

//Request interception functions
async function checkCsrfToken() {
    if (csrfToken == "" && !waitCsrfToken) {
        waitCsrfToken = true
        await apiInstance.get("api", {
            withCredentials: true,
        }).then(
            resp => {
                csrfToken = resp.headers["x-csrf-token"]
                waitCsrfToken = false
                if (process.env.NODE_ENV == "test" && resp.headers && resp.headers["set-cookie"])
                    cookie = resp.headers["set-cookie"][0]
            },
            () => {
                waitCsrfToken = false
            }
        )
    }
}
