export enum TipoContacto {
  EMAIL = "E-Mail",
  WHATAPP = "Whatsapp",
  TELF = "Teléfono",
  None = ""
}

export type Contacto = {
    tipo: TipoContacto,
    info: string,
}